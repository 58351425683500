import { graphql, StaticQuery } from 'gatsby'
import Link, { navigate } from 'gatsby-link'
import initial from 'lodash/initial'
import queryString from 'query-string'
import React, { useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ReactMarkdown from 'react-markdown'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import Grid, { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import BreadcrumbNavigation from '../../components/blocks/BreadcrumbNavigation'
import SortListSelector from '../../components/blocks/SortListSelector'
import Layout from '../../components/layouts/layout'
import FiltersContainer from './FiltersContainer'
import SearchWidget from './SearchWidget'
import ItemsContainer from './ItemsContainer'
import Menu from './Menu'
import SidebarLeft from './SidebarLeft'
import SidebarRight from './SidebarRight'
import VisualNavigation from './visualNavigation'
import InlineQuickView from './InlineQuickView'

const Container = styled.div`
  ${grid({})};
  padding: 15px 60px 70px 60px;

  @media screen and (max-width: 992px) {
    padding: 15px 40px 70px 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 25px 70px 25px;
  }

  @media screen and (max-width: 576px) {
    padding: 15px 15px 70px 15px;
  }
`

const SeoTextContainer = styled('div')`
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 15px;
  display: inherit;
  max-width: 580px;
  margin: 0 auto;

  ${down('sm')} {
    margin: 0 30px;
    padding: 0;
  }

  blockquote {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
`

const SeoText = styled('div')`
  p,
  li {
    margin-top: 0;
    margin-bottom: 22px;
    color: #6b6b6b;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.661;
    letter-spacing: 1.26px;
    text-align: justify;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 12px;
    margin-top: 53px;
    transition: all 700ms ease;
    overflow-wrap: break-word;
    font-size: 22px;

    &:hover,
    &:hover a {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }

  ul > li {
    list-style: circle;
    margin-bottom: 15px;
  }

  ol > li {
    list-style: decimal;
    margin-bottom: 15px;
  }
`

const HeaderGridUnit = styled(GridUnit)`
  text-align: center;
`

const MobileContainer = styled.div`
  width: 100%;

  ${grid({})};
`

const DesktopContainer = styled(Grid)`
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
`

const PlaceholderContainer = styled.div`
  width: 100%;
  height: 72px;
  @media screen and (max-width: 768px) {
    height: 44px;
  }
`

const ProductsHeader = styled(Grid)`
  justify-content: space-between;
`

const H1 = styled('h1')`
  position: relative;
  padding: 15px 25px;
  font-size: 20px;
  font-weight: bold;
`

const Arrow = styled('div')`
  font-size: 25px;

  &::before {
    position: absolute;
    top: 0;
    left: 5px;
    bottom: 0;
    content: '\\F105';
    font-family: FontAwesome, sans-serif;
    color: #f1574d;
    text-transform: capitalize;
    transform: rotate(-180deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ArrowContainer = styled('div')`
  @media (min-width: 768px) {
    display: none;
  }
`

const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;

  @media (max-width: 768px) {
    display: none;
  }
`

const SearchGridContainer = styled(Grid.Unit)`
  text-align: right;
  ${down('sm')} {
    text-align: left;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px
  }
`

const SwitchContainer = ({ mobileItems, desktopItems, initialSSRItems }) => {
  // Mode is either '', 'desktop' or 'mobile'
  const [mode, setMode] = React.useState('')

  const updateMode = useCallback(() => {
    setMode(window.innerWidth > 768 ? 'desktop': 'mobile')
  }, [])

  React.useEffect(()=>{
    if (typeof window !== 'undefined') {
      setMode(window.innerWidth > 768 ? 'desktop': 'mobile')
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener("resize", updateMode)
    return () => window.removeEventListener("resize", updateMode)
  }, [updateMode])

  if (mode === 'mobile') {
    return mobileItems
  }
  if (mode === 'desktop') {
    return desktopItems
  }
  return initialSSRItems
}

  const CategoryPageTemplate = props => {
  const breadcrumbs = useMemo(() => {
    const categories = props.pageContext.category
    const catKeys = ['cat1', 'cat2', 'cat3', 'cat4']
    const array = Object.keys(categories)
      .filter(key => catKeys.includes(key))
      .map(key => categories[key])
      .filter(key => key)
    return initial(array)
  }, [props.pageContext.category])

  const query = useMemo(() => {
    return queryString.parse(props.location.search)
  }, [props.location.search])

  const changeSort = useCallback(
    async e => {
      const selected = e.target.value.split(',')

      query.sort = selected[0]
      query.direction = selected[1]

      await navigate(`${props.path}?${queryString.stringify(query)}`)
    },
    [props.path, query]
  )

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulCategoryPage {
            edges {
              node {
                seoText {
                  seoText
                }
                slug
                imgNavi {
                  fluid(maxWidth: 1200) {
                    aspectRatio
                    src
                    srcSet
                    sizes
                    base64
                    srcWebp
                    srcSetWebp
                  }
                }
                imageNaviLabel1
                imageNaviLink1
                imageNaviLabel2
                imageNaviLink2
                imageNaviLabel3
                imageNaviLink3
                imageNaviLabel4
                imageNaviLink4
                imageNaviLabel5
                imageNaviLink5
                imageNaviLabel6
                imageNaviLink6
                imageNaviLabel7
                imageNaviLink7
                imageNaviLabel8
                imageNaviLink8
              }
            }
          }
        }
      `}
      render={({ allContentfulCategoryPage }) => {
        const [contentful] = allContentfulCategoryPage.edges.filter(
          ({ node }) => node.slug === props.pageContext.menu.amber_url
        )

        return (
          <>
            <Helmet
              title={props.pageContext.category.seo_title}
              meta={[
                {
                  name: 'description',
                  content: props.pageContext.category.seo_description
                },
                {
                  name: 'keywords',
                  content: props.pageContext.category.keywords
                },
                {
                  name: 'robots',
                  content: props.pageContext.category.index
                }
              ]}
              link={[
                {
                  rel: 'canonical',
                  href: props.pageContext.category.canonical
                }
              ]}
              script={[
                {
                  type: 'application/ld+json',
                  innerHTML: `
                    {
                      "@context": "https://schema.org",
                      "@type": "BreadcrumbList",
                      "itemListElement": [
                        {
                          "@type": "ListItem",
                          "position": 1,
                          "item": {
                            "@id": "https://amber-living.de",
                            "name": "Home"
                          }
                        }${breadcrumbs.length > 0 ? ',' : ''}` +
                        breadcrumbs.map((breadcrumb, index) => (
                          `
                            {
                              "@type": "ListItem",
                              "position": ${index + 2},
                              "item": {
                                "@id": "https://amber-living.de/${breadcrumb}",
                                "name": "${breadcrumb}"
                              }
                            }
                          `
                        )) +
                      `]
                    }
                  `
                }
              ]}
            />
            <Layout navigation={props.pageContext.navigation}>
              <Container>
                <Grid.Unit size={{ xs: 1 }}>
                  <ProductsHeader>
                    <GridUnit size={{ xs: 1 }}
                      style={{ position: 'relative', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <ArrowContainer>
                        {breadcrumbs[1] && (
                          <Link
                            to={'/' + breadcrumbs[breadcrumbs.length - 2]}
                            style={{
                              position: 'absolute',
                              width: '30px',
                              zIndex: '10'
                            }}
                          >
                            <Arrow />
                          </Link>
                        )}
                      </ArrowContainer>
                      <Grid.Unit size={{sm: 1 / 2}}>
                        <H1>{props.pageContext.category.h1_title}</H1>
                      </Grid.Unit>
                      <SearchGridContainer size={{sm: 1 / 2}}>
                        <SearchWidget {...props} style={{width: "100%"}}/>
                      </SearchGridContainer>
                    </GridUnit>
                    <ProductHeader>
                      {!query.inlineqv && <BreadcrumbNavigation>
                        {breadcrumbs.map(category => (
                          <BreadcrumbNavigation.Item key={category}>
                            <BreadcrumbNavigation.StyledLink as={Link} to={'/' + category}>
                              {category}
                            </BreadcrumbNavigation.StyledLink>
                          </BreadcrumbNavigation.Item>
                        ))}
                      </BreadcrumbNavigation>}
                    </ProductHeader>
                  </ProductsHeader>
                  {contentful && contentful.node.imgNavi && !props.location.search && (
                    <VisualNavigation {...props} contentful={contentful} />
                  )}
                  {query.inlineqv && (
                    <InlineQuickView {...props} />
                  )}
                </Grid.Unit>
                <Grid.Unit size={{ md: 1 / 6 }}>
                  <Menu menu={props.pageContext.menu} />
                </Grid.Unit>
                <Grid.Unit size={{ xs: 1, md: 5 / 6 }}>
                <SwitchContainer
                    mobileItems={
                      <MobileContainer>
                        <HeaderGridUnit size={{ xs: 1 / 2, sm: 1 / 2 }}>
                          <SidebarLeft menu={props.pageContext.menu} />
                        </HeaderGridUnit>
                        <HeaderGridUnit size={{ xs: 1 / 2, sm: 1 / 2 }}>
                          <SidebarRight
                            sortOnChange={changeSort}
                            selected={query.sort + ',' + query.direction}
                            {...props}
                          />
                        </HeaderGridUnit>
                      </MobileContainer>
                    }
                    desktopItems={
                      <DesktopContainer>
                        <FiltersContainer {...props} />
                      </DesktopContainer>
                    }
                    initialSSRItems={<PlaceholderContainer />}
                  />
                  <SortListSelector>
                    <SortListSelector.Text>
                      Sortieren nach...
                    </SortListSelector.Text>
                    <SortListSelector.Select
                      onChange={changeSort}
                      value={query.sort + ',' + query.direction}
                    >
                      <option value='prodRanking,desc'>Beliebte</option>
                      <option value='prodPrice,asc'>Preis aufsteigend</option>
                      <option value='prodPrice,desc'>Preis absteigend</option>
                    </SortListSelector.Select>
                  </SortListSelector>
                  <ItemsContainer {...props} />
                </Grid.Unit>
                {contentful && (
                  <SeoTextContainer>
                    <SeoText>
                      <ReactMarkdown source={contentful.node.seoText.seoText} />
                    </SeoText>
                  </SeoTextContainer>
                )}
              </Container>
            </Layout>
          </>
        )
      }}
    />
  )
}

export default CategoryPageTemplate
