import styled from 'styled-components'
import Column from './Column'
import Description from './Description'
import Image from './Image'
import Price from './Price'
import StyledLink from './StyledLink'
import Title from './Title'

const ProductBigItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #eeeeee40;
  padding: 0;
  margin: 5px;
  border: #9999991f;
  border-style: double;
  border-width: thin;

  @media screen and (min-width: 100px) and (max-width: 1000px) {
    ${Column} {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 5px;
      }
    }
  }

  @media screen and (max-width: 100px) {
    flex-direction: column;
    align-items: center;
    & > * {
      &:first-child {
        margin-top: 15px;
      }
      margin-bottom: 10px;
    }
  }
`

ProductBigItem.Description = Description
ProductBigItem.Title = Title
ProductBigItem.Image = Image
ProductBigItem.Price = Price
ProductBigItem.StyledLink = StyledLink
ProductBigItem.Column = Column

export default ProductBigItem
