import styled, { css } from 'styled-components'

const price = css`
  padding: 16px 25px 0;
  overflow-x: hidden;
`

const ScrollableBox = styled('div')`
  max-height: 250px;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px 0;
  overflow-y: auto;
  border-top: 1px solid #bfbfbf;

  ${props => props.price && price};
`

export default ScrollableBox
