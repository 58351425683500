import styled from 'styled-components'
import ProductBigItem from '../../../components/blocks/ProductBigItem'


const QVStyledLinkDescription = styled(ProductBigItem.StyledLink)`
    
    max-width: 420px;
    margin: auto;
    padding: 0;

    @media screen and (min-width: 1000px) {
       width: 100%; 
    }

    @media screen and (min-width: 100px) and (max-width: 1000px) {
       max-width: 520px; 
    }
`;

export default QVStyledLinkDescription