import styled from 'styled-components'

const SaleLabel = styled('label')`
  display: block;
  margin-bottom: 16px;

  & > input {
    margin-right: 20px;
    cursor: pointer;
  }
`

export default SaleLabel
