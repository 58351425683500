import { faSlidersH, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import React, { useMemo, useState } from 'react'
import FilterButton from '../../components/blocks/FilterButton'
import Sidebar from '../../components/blocks/Sidebar'
import SidebarList from '../../components/blocks/SidebarList'

const SidebarLeft = props => {
  const [show, setShow] = useState(false)

  const navUrlLinks = useMemo(() => {
    const menu = props.menu
    const urlLinks = [
      'Navi_url_link1',
      'Navi_url_link2',
      'Navi_url_link3',
      'Navi_url_link4',
      'Navi_url_link5',
      'Navi_url_link6',
      'Navi_url_link7'
    ]
    return Object.keys(menu)
      .filter(key => urlLinks.includes(key))
      .map(key => menu[key])
      .filter(key => key)
  }, [props])

  const navUrlLabels = useMemo(() => {
    const menu = props.menu
    const urlLabels = [
      'Navi_url_label1',
      'Navi_url_label2',
      'Navi_url_label3',
      'Navi_url_label4',
      'Navi_url_label5',
      'Navi_url_label6',
      'Navi_url_label7'
    ]
    return Object.keys(menu)
      .filter(key => urlLabels.includes(key))
      .map(key => menu[key])
      .filter(key => key)
  }, [props])
  return (
    <>
      <Sidebar show={show} left>
        <Sidebar.Content>
          <Sidebar.CloseButton onClick={() => setShow(!show)}>
            <Sidebar.Icon icon={faTimes} />
          </Sidebar.CloseButton>
          <Sidebar.Header>
            <Sidebar.Title>Kategorien</Sidebar.Title>
          </Sidebar.Header>
          <SidebarList>
            {navUrlLinks.map((element, i) => (
              <SidebarList.Item key={element}>
                <SidebarList.StyledLink as={Link} to={'/' + element}>
                  {navUrlLabels[i]}
                </SidebarList.StyledLink>
              </SidebarList.Item>
            ))}
          </SidebarList>
        </Sidebar.Content>
      </Sidebar>
      <FilterButton onClick={() => setShow(!show)}>
        <FilterButton.Icon icon={faSlidersH} style={{ height: '18px', width: '18px' }} />
        <FilterButton.Text>Kategorien</FilterButton.Text>
      </FilterButton>
    </>
  )
}

export default SidebarLeft
