import React, { useContext, useMemo } from 'react'
import { QueryParamsContext } from '../../providers/QueryParamsProvider'
import ProductBigItemElement from './ProductBigItemElement'
import ProductItemElement from './ProductItemElement'

const viewBigElement = index => {
  const elementsBeforeBigItem = 12
  const maxBlocks = 4
  if (elementsBeforeBigItem * maxBlocks < index) {
    return false
  }
  return index % elementsBeforeBigItem === 0
}

const ItemsDisplay = ({ standaloneItems, amberUrl, items }) => {
  const { query } = useContext(QueryParamsContext)

  let bigItem = 0

  const isDefaultQuery = useMemo(() => {
    return (
      Object.keys(query).length === 2 &&
      query.direction === 'desc' &&
      query.sort === 'prodRanking'
    )
  }, [query])

  return (
    <>
      {items.length > 0 &&
        items.map((item, index) => {
          if (isDefaultQuery && viewBigElement(index + 1)) {
            return (
              <React.Fragment key={index}>
                <ProductItemElement {...item} url_id={amberUrl} index={index} />
                {standaloneItems[bigItem] && (
                  <ProductBigItemElement
                    {...standaloneItems[bigItem++]}
                    url_id={amberUrl}
                  />
                )}
              </React.Fragment>
            )
          }
          return <ProductItemElement key={index} {...item} url_id={amberUrl} index={index} />
        })}
    </>
  )
}

export default ItemsDisplay
