import styled from 'styled-components'

const InfoGrid = styled('div')`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  padding-left: 15px;
  
  b {
    font-weight: bold;
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    padding-left: unset;
    margin: 32px auto 0;
  }
`

export default InfoGrid
