import styled from 'styled-components'

const Item = styled('div')`
  position: relative;
`

const Item1 = styled(Item)`
  grid-area: item1;
`

const Item2 = styled(Item)`
  grid-area: item2;
`

const Item3 = styled(Item)`
  grid-area: item3;
`

const Item4 = styled(Item)`
  grid-area: item4;
`

const Item5 = styled(Item)`
  grid-area: item5;
`

const Item6 = styled(Item)`
  grid-area: item6;
`

const Item7 = styled(Item)`
  grid-area: item7;
`

const Item8 = styled(Item)`
  grid-area: item8;
`

export { Item1, Item2, Item3, Item4, Item5, Item6, Item7, Item8 }
