import styled from 'styled-components'
import Grid from 'styled-components-grid'
import Button from './Button'
import ButtonText from './ButtonText'
import Cell from './Cell'
import FilterBox from './FilterBox'
import Header from './Header'
import Icon from './Icon'
import InputRow from './InputRow'
import InputWrapper from './InputWrapper'
import PriceInput from './PriceInput'
import SaleLabel from './SaleLabel'
import Save from './Save'
import SaveContainer from './SaveContainer'
import ScrollableBox from './ScrollableBox'

const Filters = styled(Grid)`
  flex-direction: row;
  padding: 10px 0 10px 15px;
`

Filters.Button = Button
Filters.ButtonText = ButtonText
Filters.Cell = Cell
Filters.Icon = Icon
Filters.FilterBox = FilterBox
Filters.ScrollableBox = ScrollableBox
Filters.SaveContainer = SaveContainer
Filters.Save = Save
Filters.Header = Header
Filters.InputRow = InputRow
Filters.InputWrapper = InputWrapper
Filters.PriceInput = PriceInput
Filters.SaleLabel = SaleLabel

export default Filters
