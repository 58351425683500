import styled from 'styled-components'

const SaveContainer = styled('footer')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-left: -16px;
  border-top: 1px solid #bfbfbf;

  @media screen and (min-width: 768px) {
    width: calc(100% + 32px);
  }
`

export default SaveContainer
