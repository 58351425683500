import React from 'react'
import Button from './Button'
import ButtonText from './ButtonText'
import InfoGrid from './InfoGrid'
import Grid from 'styled-components-grid'
import ProductBigItem from '../../../components/blocks/ProductBigItem'
import ProductItemDetails from '../../../components/blocks/ProductItemDetails'
import priceFormatter from '../../../components/elements/PriceFormatter'
import QVStyledLink from './QVStyledLink'
import QVStyledLinkDescription from './QVStyledLinkDescription'

const QVColumn = ({children, bottom, style, item, handleDataLayer}) => (
<ProductBigItem.Column style={{style}} bottom={bottom}>
  <QVStyledLink
              href={item && (item.deepLink || item.deeplink)}
              rel='nofollow noopener'
              target='_blank'
              onClick={handleDataLayer}
            >
    {children}
  </QVStyledLink>
</ProductBigItem.Column>)

const QVColumnDescription = ({children, bottom, style, item, handleDataLayer}) => (
<ProductBigItem.Column style={{style}} bottom={bottom}>
  <QVStyledLinkDescription
              href={item && (item.deepLink || item.deeplink)}
              rel='nofollow noopener'
              target='_blank'
              onClick={handleDataLayer}
            >
    {children}
  </QVStyledLinkDescription>
</ProductBigItem.Column>)

const ProductBigItemElementQV = ({item, handleDataLayer, infoArray }) => {
  return (
    <Grid.Unit
      size={{ xs: 1 }}
    >
      <ProductBigItem item={item} handleDataLayer={handleDataLayer}>
        <QVColumn>
            <ProductBigItem.Image
              src={item.prodImgUrl1}
              className='lazyload'
              alt={item.prodTitle}
              rel='nofollow noopener'
              height={450}
            />
        </QVColumn>
        <QVColumnDescription bottom  item={item} handleDataLayer={handleDataLayer}>
            <ProductBigItem.Title style={{ padding:0}}>
              {item.prodTitle}

            </ProductBigItem.Title>
            <ProductBigItem.Description>
              {item.amber_product_description}
            </ProductBigItem.Description>
            <Button as='div' style={{ marginLeft: 'auto', marginRight: 'auto'}}>
              <ButtonText>
                Zum Angebot
              </ButtonText>
            </Button>
            <ProductBigItem.Price>
              <ProductItemDetails.ActualPrice>
                {priceFormatter(item.prodPrice)}
              </ProductItemDetails.ActualPrice>
              {!!item.prodPriceOld && (
                <ProductItemDetails.Discount>
                  {priceFormatter(item.prodPriceOld)}
                </ProductItemDetails.Discount>
              )}
            </ProductBigItem.Price>
            <InfoGrid>
              {
                infoArray.map((info) => (
                  <p key={info.label}>
                    <b>{info.label}:</b> {info.value}
                  </p>
                ))
              }
            </InfoGrid>
        </QVColumnDescription>
        <QVColumn item={item} handleDataLayer={handleDataLayer}>
          {item.prodImgUrl2 && (
              <ProductBigItem.Image
                src={item.prodImgUrl2}
                className='lazyload'
                alt={item.prodTitle}
                rel='nofollow noopener'
                height={450}
              />
          )}
        </QVColumn>
      </ProductBigItem>
    </Grid.Unit>
  )
}

export default ProductBigItemElementQV
