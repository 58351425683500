import styled from 'styled-components'

const StyledLink = styled.a`
  transition: all 700ms ease;
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    outline: none;
    text-decoration: none;
  }
`

export default StyledLink
