import styled from 'styled-components'
import Item from './Item'
import StyledLink from './StyledLink'
import Text from './Text'

const SidebarList = styled.ol`
  padding: 24px;
`

SidebarList.Item = Item
SidebarList.StyledLink = StyledLink
SidebarList.Text = Text

export default SidebarList
