import { Link } from 'gatsby'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import CategoryList from '../../components/blocks/CategoryList'
import CategoryMenu from '../../components/blocks/CategoryMenu'

const MenuContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 130px;
  top: 200px;
  position: sticky;
  padding: 0 5px;

  @media screen and (max-width: 992px) {
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const Menu = props => {
  const navUrlLinks = useMemo(() => {
    const menu = props.menu
    const urlLinks = [
      'Navi_url_link1',
      'Navi_url_link2',
      'Navi_url_link3',
      'Navi_url_link4',
      'Navi_url_link5',
      'Navi_url_link6',
      'Navi_url_link7'
    ]
    return Object.keys(menu)
      .filter(key => urlLinks.includes(key))
      .map(key => menu[key])
      .filter(key => key)
  }, [props])
  const navUrlLabels = useMemo(() => {
    const menu = props.menu
    const urlLabels = [
      'Navi_url_label1',
      'Navi_url_label2',
      'Navi_url_label3',
      'Navi_url_label4',
      'Navi_url_label5',
      'Navi_url_label6',
      'Navi_url_label7'
    ]
    return Object.keys(menu)
      .filter(key => urlLabels.includes(key))
      .map(key => menu[key])
      .filter(key => key)
  }, [props])
  return (
    <MenuContainer>
      <CategoryMenu>
        {/*<CategoryMenu.Title>{props.menu.navi_name}</CategoryMenu.Title>*/}
        <CategoryList>
          {navUrlLinks.map((item, i) => (
            <CategoryList.Item key={item}>
              <CategoryList.StyledLink as={Link} to={'/' + item}>
                {navUrlLabels[i]}
              </CategoryList.StyledLink>
            </CategoryList.Item>
          ))}
        </CategoryList>
      </CategoryMenu>
    </MenuContainer>
  )
}

export default Menu
