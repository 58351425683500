import styled from 'styled-components'
import Title from './Title'

const CategoryMenu = styled.div`
  position: sticky;
  top: 120px;
  font-family: Montserrat, sans-serif;
`

CategoryMenu.Title = Title

export default CategoryMenu
