import styled, { css } from 'styled-components'
import LoadableImage from '../../elements/LoadableImage'

export const LastImageCSS = css`
  max-height: 450px;
  min-height: 150px;
  overflow: hidden;
`

const Image = styled(LoadableImage)`
  width: 100%;
  display: block;
  transition: all ease 500ms;
  padding: 0;
  max-width: 320px;
  position: relative !important;

  &:hover {
    transform: scale(1.05);
  }
`

export default Image
