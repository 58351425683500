import styled, { css } from 'styled-components'
import Image, { LastImageCSS } from './Image'

const bottom = css`
  @media screen and (min-width: 200px) and (max-width: 1000px) {
    order: 3;
    width: 100%;
    padding: 10px 0 10px 0;
  }
`

const Column = styled.div`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: white;
  padding: 0;

  &:last-child {
    ${Image} {
      ${LastImageCSS}
    }
  }

  @media screen and (max-width: 1000px) {
    width: calc(50% - 0px);
  }

  @media screen and (max-width: 200px) {
    width: 90%;
  }

  ${props => props.bottom && bottom};
`

export default Column
