import { faSortAmountDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useState } from 'react'
import Collapsible from 'react-collapsible'
import '../../assets/collapsible.css'
import FilterButton from '../../components/blocks/FilterButton'
import Filters from '../../components/blocks/Filters'
import Sidebar from '../../components/blocks/Sidebar'
import CheckboxList from '../../components/elements/CheckboxList'
import RadioList from '../../components/elements/RadioList'
import { QueryParamsContext } from '../../providers/QueryParamsProvider'
import applyFilters from './applyFilters'

const deliveryData = [
  'sofort lieferbar',
  'bis 2 Wochen',
  'bis 4 Wochen',
  'bis 8 Wochen',
  'über 8 Wochen'
]

const SidebarRight = ({ pageContext }) => {
  const { query, location } = useContext(QueryParamsContext)
  const filters = pageContext.filters
  const brandsData = filters.brands.map(brand => {
    return {
      value: brand.prodBrand,
      label: brand.prodBrand
    }
  })
  const colorsData = filters.colors.map(color => {
    return {
      value: color.prodColor,
      label: color.prodColor
    }
  })
  const shopsData = filters.shops.map(shop => {
    return shop.merchant_name
  })

  const [show, setShow] = useState(false)
  const [colors, setColors] = useState(query.color ? query.color : [])
  const [brands, setBrands] = useState(query.brand ? query.brand : [])
  const [delivery, setDelivery] = useState(
    query.delivery ? query.delivery : undefined
  )
  const [shop, setShop] = useState(query.shop ? query.shop : undefined)
  const [sale, setSale] = useState(query.sale ? Boolean(query.sale) : false)

  const onSave = () => {
    setShow(false)

    const filters = {
      colors: colors,
      brands: brands,
      delivery: delivery,
      shop: shop,
      sale: sale
    }

    applyFilters({ filters, query, location })
  }

  return (
    <>
      <Sidebar show={show} right>
        <Sidebar.Content>
          <Sidebar.CloseButton onClick={() => setShow(!show)}>
            <Sidebar.Icon icon={faTimes} />
          </Sidebar.CloseButton>
          <Sidebar.Header>
            <Sidebar.Title>Filters</Sidebar.Title>
          </Sidebar.Header>
          <Collapsible trigger='Price' open>
            {/* <Filters.InputRow> */}
            {/*  <Filters.InputWrapper> */}
            {/*    <Filters.PriceInput */}
            {/*      type='number' */}
            {/*      value={price.min} */}
            {/*      onChange={(value) => { */}
            {/*        if (value && value.target.value <= price.max) { */}
            {/*          setPrice({ min: Number(value.target.value), max: Number(price.max) }) */}
            {/*        } */}
            {/*      }} */}
            {/*    /> */}
            {/*  </Filters.InputWrapper> */}
            {/*  <Filters.InputWrapper> */}
            {/*    <Filters.PriceInput */}
            {/*      type='number' */}
            {/*      value={price.max} */}
            {/*      onChange={(value) => { */}
            {/*        if (value && value.target.value <= priceData.max) { */}
            {/*          setPrice({ min: Number(price.min), max: Number(value.target.value) }) */}
            {/*        } */}
            {/*      }} */}
            {/*    /> */}
            {/*  </Filters.InputWrapper> */}
            {/* </Filters.InputRow> */}
            {/* <InputRange */}
            {/*  formatLabel={value => `${value} €`} */}
            {/*  minValue={priceData.min} */}
            {/*  maxValue={priceData.max} */}
            {/*  value={price} */}
            {/*  onChange={value => { */}
            {/*    // This check is required, because react-input-range is doing it wrong */}
            {/*    if (value && value.min >= priceData.min && value.max <= priceData.max) { */}
            {/*      setPrice({ min: value.min, max: value.max }) */}
            {/*    } */}
            {/*  }} */}
            {/* /> */}
            <Filters.SaleLabel>
              <input
                type='checkbox'
                checked={sale}
                onChange={event => {
                  setSale(event.target.checked)
                }}
              />
              Im Sale
            </Filters.SaleLabel>
          </Collapsible>
          <Collapsible trigger='Colors'>
            <CheckboxList
              data={colorsData}
              values={colors}
              setData={setColors}
            />
          </Collapsible>
          <Collapsible trigger='Marke'>
            <CheckboxList
              data={brandsData}
              values={brands}
              setData={setBrands}
            />
          </Collapsible>
          <Collapsible trigger='Lieferzeit'>
            <RadioList
              data={deliveryData}
              value={delivery}
              setData={setDelivery}
            />
          </Collapsible>
          <Collapsible trigger='Shop'>
            <RadioList data={shopsData} value={shop} setData={setShop} />
          </Collapsible>
          <Filters.SaveContainer>
            <Filters.Save onClick={onSave}>Speichern</Filters.Save>
          </Filters.SaveContainer>
        </Sidebar.Content>
      </Sidebar>
      <FilterButton onClick={() => setShow(!show)}>
        <FilterButton.Icon icon={faSortAmountDown} style={{ height: '18px', width: '18px' }} />
        <FilterButton.Text>Filter</FilterButton.Text>
      </FilterButton>
    </>
  )
}

export default SidebarRight
