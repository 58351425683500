import styled from 'styled-components'

const Column = styled('div')`
  margin-top: 2px;
  padding: 3px 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  width: 100%;
  
  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`

export default Column
