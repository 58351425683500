import sortBy from 'lodash/sortBy'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import APIClient from '../api-client'

import { QueryParamsContext } from '../providers/QueryParamsProvider'

const _LIMIT_ = 52

const useItems = props => {
  const { items: bigQueryItems, category } = props

  const { query } = useContext(QueryParamsContext)
  const [status, setStatus] = useState('idle')

  const preItems = useMemo(() => {
    if (Object.keys(query).length > 2) {
      return []
    }
    return bigQueryItems
  }, [bigQueryItems, query])

  const [items, setItems] = useState(preItems)

  const ranking = useMemo(() => {
    const sortedByRanking = sortBy(items, function (item) { return item.node?.prodRanking })

    return sortedByRanking[0]?.node?.prodRanking || 1
  }, [items])

  const getMoreItems = useCallback(async (isInitialLoad) => {
    try {
      setStatus('loading')

      const response = await APIClient.getItems({
        catId: category.amber_cat_id,
        color: query.color,
        brand: query.brand,
        shop: query.shop,
        sale: query.sale,
        sort: query.sort,
        ranking: (query.sort === 'prodRanking' && !isInitialLoad) ? ranking : undefined,
        direction: query.direction,
        text: query.text,
        offset: query.sort !== 'prodRanking' ? items.length : undefined,
        limit: _LIMIT_
      })

      const newServerItems = response.map(r => ({
        node: {
          ...r
        }
      }))

      const newItems = [...items, ...newServerItems]

      setItems(newItems)

      if (response.length < _LIMIT_) {
        setStatus('end')
      } else {
        setStatus('idle')
      }
    } catch (e) {
      setStatus('error')
      console.log(e)
    }
  }, [items, query, ranking, category.amber_cat_id])

  const [initialQuery, setInitialQuery] = useState(query)

  useEffect(() => {
    const resetItems = () => {
      console.log('set items[]')
      setItems([])
      setStatus('idle')
    }

    /**
     Initial Query is needed because useItems is a hook not a provider
     that can be used in other "filters" that are changing our query.

     Later we have to change useItems to a provider that is scoped on the categoryPage
     or other page that need items
     */
    if (initialQuery !== query) {
      resetItems()
      setInitialQuery(query)
    }
  }, [query, initialQuery])

  useEffect(() => {
    const getInitialItems = async () => {
      await getMoreItems(true)
    }

    if (items.length === 0 && status === 'idle') {
      getInitialItems()
    }
  }, [items, getMoreItems, status])

  return {
    items,
    status,
    getMoreItems
  }
}

export default useItems
