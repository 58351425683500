import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Filters from '../../components/blocks/Filters'

const FilterButton = props => (
  <Filters.Cell size={{ xs: 1 / 3, lg: 1 / 5 }}>
    <Filters.Button data-index-number={props.boxNumber}>
      <Filters.ButtonText>
        {props.label}
        <Filters.Icon icon={faAngleDown} style={{ maxWidth: '11.25px' }} />
      </Filters.ButtonText>
      <Filters.FilterBox
        active={props.activeBox === props.boxNumber}
        right={props.right}
        large={props.large}
      >
        <Filters.Header />
        <Filters.ScrollableBox price={props.price}>
          {props.children}
        </Filters.ScrollableBox>
        <Filters.SaveContainer>
          <Filters.Save onClick={props.onSave}>Speichern</Filters.Save>
        </Filters.SaveContainer>
      </Filters.FilterBox>
    </Filters.Button>
  </Filters.Cell>
)

export default FilterButton
