import styled from 'styled-components'

const Price = styled.p`
  margin: 0;
  line-height: 1;
  color: #6d6d6d;
  font-family: Merriweather, sans-serif;
  letter-spacing: 1.2px;
  font-size: 13px;
  padding: 0 15px;
`

export default Price
