import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const RadioIcon = styled(FontAwesomeIcon)`
  display: none;
  position: absolute;
  left: 18px;
`

const Button = styled('button')`
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  font-weight: 400;
  padding: 8px 0 8px 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;

  &[data-active='true'] ${RadioIcon} {
    display: flex;
  }
`

const CheckboxList = ({ data, values, setData }) => (
  <Container>
    {data.map(item => (
      <Button
        key={item.value}
        data-active={values.indexOf(item.value) !== -1}
        onClick={() => {
          values.indexOf(item.value) === -1
            ? setData(values.concat([item.value]))
            : setData(values.filter(element => element !== item.value))
        }}
      >
        <RadioIcon icon={faCheck} />
        {item.label}
      </Button>
    ))}
  </Container>
)

export default CheckboxList
