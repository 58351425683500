import styled from 'styled-components'

const ButtonText = styled.span`
  background: transparent;
  display: inline-block;
  font-size: 12px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
  line-height: 0.75;
  color: #ffffff;
  transition: all ease 700ms;

  &:before {
    background: #ffffff none repeat scroll 0 0;
    bottom: 0;
    content: '';
    height: 1px;
    left: -10px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 5px;
    transition: all 700ms ease;
  }

  &:after {
    background: #ffffff none repeat scroll 0 0;
    bottom: 0;
    content: '';
    height: 1px;
    right: -10px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 5px;
    left: auto;
    transition: all 700ms ease;
  }
`

export default ButtonText
