import styled from 'styled-components'

const InputWrapper = styled('div')`
  border-radius: 3px;
  border: 1px solid #bfbfbf;
  width: 125px;
  height: 40px;
  position: relative;
  font-size: 14px;
  font-weight: 700;

  &:after {
    content: '€';
    position: absolute;
    height: 40px;
    line-height: 40px;
    right: 0;
    top: 0;
    padding: 0 8px;
  }
`

export default InputWrapper
