import styled from 'styled-components'

const Item = styled.li`
  padding-left: 0.5rem;
  display: flex;

  &::before {
    padding-left: 0.35rem;
    padding-right: 0.25rem;
    content: '\\F105';
    font-family: FontAwesome, sans-serif;
    color: #f1574d;
    display: inline-block;
    text-transform: capitalize;
  }
`

export default Item
