import styled, { css } from 'styled-components'
import UniversalButtonText from './UniversalButtonText'

const LeftCSS = css`
  left: 30px;
`

const RightCSS = css`
  right: 30px;
`

const UniversalButton = styled.a`
  position: absolute;
  bottom: 20px;
  ${props => props.align === 'left' && LeftCSS};
  ${props => props.align === 'right' && RightCSS};
  top: auto;
  background: #000000 none repeat scroll 0 0;
  height: 30px;
  text-align: center;
  width: 190px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 700ms;
  text-decoration: none;

  &:hover {
    background-color: #ffffff;
    ${UniversalButtonText} {
      color: #000000;
      &:before,
      &:after {
        color: #000000;
      }
    }
  }

  @media (max-width: 992px) {
    width: 75%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  @media (max-width: 576px) {
    left: 0;
    bottom: 0;
  }
`

UniversalButton.defaultProps = {
  align: 'left'
}

UniversalButton.Text = UniversalButtonText

export default UniversalButton
