import styled, { css } from 'styled-components'
import Icon from './Icon'

const right = css`
  > ${Icon} {
    margin-right: 5px;
  }
`

const left = css`
  width: 90%;

  > ${Icon} {
    position: absolute;
    right: 12px;
  }
`

const ButtonText = styled('header')`
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.3px;
  vertical-align: middle;
  flex: 1;

  ${props => props.right && right};
  ${props => !props.right && left};
`

export default ButtonText
