import styled, { css } from 'styled-components'

const invert = css`
  background-color: black;
  color: white;
  padding: 12px;
`

const Button = styled('div')`
  position: relative;
  padding: 12px 24px 12px 12px;
  cursor: pointer;
  border: 1px solid #bfbfbf;

  ${props => props.invert && invert};
`

export default Button
