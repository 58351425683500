import React, { useContext, useState } from 'react'
import { QueryParamsContext } from '../../providers/QueryParamsProvider'
import applyFilters from './applyFilters'
import SearchField from "react-search-field";
import '../../assets/search-field.css'

const SearchWidget = ({ pageContext }) => {
  const { query, location } = useContext(QueryParamsContext)

  const [text, setText] = useState(query.text ? query.text : undefined)

  const onSave = () => {
    const filters = {
      text: text
    }

    applyFilters({ filters, query, location })
  }

  return (
      <SearchField
        placeholder="Search..."
        onChange={(t) => setText(t)}
        searchText={text}
        onEnter={onSave}
        onSearchClick={onSave}
        classNames="search-field"
      />
  )
}

export default SearchWidget
