import styled, { css } from 'styled-components'

const active = css`
  display: flex;
`

const right = css`
  right: 0;
  margin-right: -1px;
  left: unset;
  margin-left: unset;
`

const large = css`
  width: 350px;
`

const FilterBox = styled('div')`
  display: none;
  position: absolute;
  top: 50px;
  z-index: 9;
  cursor: default;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  padding: 0 16px;
  min-height: 100px;
  width: 250px;
  left: 0;
  margin-left: -1px;
  flex-direction: column;

  ${props => props.large && large};
  ${props => props.active && active};
  ${props => props.right && right};
`

export default FilterBox
