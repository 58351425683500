import React, { useCallback } from 'react'
import Grid from 'styled-components-grid'
import ProductBigItem from '../../components/blocks/ProductBigItem'
import ProductItemDetails from '../../components/blocks/ProductItemDetails'
import priceFormatter from '../../components/elements/PriceFormatter'

const ProductBigItemElement = (item) => {
  const handleDataLayer = useCallback(() => {
    window.dataLayer.push({
      amberCatId: item.amber_cat_id,
      simpleSku: item.simpleSku,
      url_id: item.url_id,
      prodColor: item.prodColor,
      prodBrand: item.prodBrand,
      prodPrice: item.prodPrice,
      prodPriceOld: item.prodPriceOld,
      prodRanking: item.ranking,
      prodTitle: item.prodTitle,
      merchantName: item.merchant_name,
      pageType: 'categoryPageTemplateBigItem',
      event: 'productClick'
    })
  }, [item])

  return (
    <Grid.Unit
      size={{ xs: 1 }}
      as='li'
    >
      <ProductBigItem>
        <ProductBigItem.Column>
          <ProductBigItem.StyledLink
            href={item.deepLink || item.deeplink}
            rel='nofollow noopener'
            target='_blank'
            onClick={handleDataLayer}
          >
            <ProductBigItem.Image
              src={item.imgUrl1}
              className='lazyload'
              alt={item.prodTitle}
              rel='nofollow noopener'
              height={450}
            />
          </ProductBigItem.StyledLink>
        </ProductBigItem.Column>
        <ProductBigItem.Column bottom>
          <ProductBigItem.StyledLink
            href={item.deepLink || item.deeplink}
            rel='nofollow noopener'
            target='_blank'
            onClick={handleDataLayer}
          >
            <ProductBigItem.Title>
              {item.prodTitle}
            </ProductBigItem.Title>
            <ProductBigItem.Description>
              {item.amber_product_description}
            </ProductBigItem.Description>
            <ProductBigItem.Price>
              <ProductItemDetails.ActualPrice>
                {priceFormatter(item.prodPrice)}
              </ProductItemDetails.ActualPrice>
              {!!item.prodPriceOld && (
                <ProductItemDetails.Discount>
                  {priceFormatter(item.prodPriceOld)}
                </ProductItemDetails.Discount>
              )}
            </ProductBigItem.Price>
          </ProductBigItem.StyledLink>
        </ProductBigItem.Column>
        <ProductBigItem.Column>
          {item.imgUrl2 && (
            <ProductBigItem.StyledLink
              href={item.deepLink || item.deeplink}
              rel='nofollow noopener'
              target='_blank'
              onClick={handleDataLayer}
            >
              <ProductBigItem.Image
                src={item.imgUrl2}
                className='lazyload'
                alt={item.prodTitle}
                rel='nofollow noopener'
                height={450}
              />
            </ProductBigItem.StyledLink>
          )}
        </ProductBigItem.Column>
      </ProductBigItem>
    </Grid.Unit>
  )
}

export default ProductBigItemElement
