import styled from 'styled-components'

const Loader = styled.div`
  display: inline-block;
  width: 90px;
  height: 90px;
  &:after {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${props => (props.color ? props.color : '#000000')};
    border-color: ${props => (props.color ? props.color : '#000000')}
      transparent ${props => (props.color ? props.color : '#000000')}
      transparent;
    animation: dual-ring 1.2s linear infinite;
  }
`

export default Loader
