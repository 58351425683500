import styled from 'styled-components'

const CloseButton = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 10px;
  left: 10px;
  z-index: 100;
`

export default CloseButton
