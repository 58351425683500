import styled, { css } from 'styled-components'
import CloseButton from './CloseButton'
import Content from './Content'
import Header from './Header'
import Icon from './Icon'
import Title from './Title'

const show = css`
  transform: translateX(0);
`

const left = css`
  left: 0;
  transform: translateX(-110vw);
`

const right = css`
  right: 0;
  transform: translateX(110vw);
`

const Sidebar = styled.nav`
  position: fixed;
  top: 0;
  ${props => props.left && left}
  ${props => props.right && right}
  width: 100vw;
  height: 100vh;
  transition: 1s transform;
  z-index: 100;
  ${props => props.show && show};
`

Sidebar.defaultProps = {
  left: false,
  right: false
}

Sidebar.Content = Content
Sidebar.CloseButton = CloseButton
Sidebar.Title = Title
Sidebar.Header = Header
Sidebar.Icon = Icon

export default Sidebar
