import React, { useContext, useEffect, useState } from 'react'
import '../../assets/react-input-range.css'
import Filters from '../../components/blocks/Filters'
import CheckboxList from '../../components/elements/CheckboxList'
import RadioList from '../../components/elements/RadioList'
import { QueryParamsContext } from '../../providers/QueryParamsProvider'
import applyFilters from './applyFilters'
import FilterButton from './FilterButton'
import queryString from 'query-string'

const deliveryData = [
  'sofort lieferbar',
  'bis 2 Wochen',
  'bis 4 Wochen',
  'bis 8 Wochen',
  'über 8 Wochen'
]

const FiltersContainer = ({ pageContext }) => {
  const { query, location } = useContext(QueryParamsContext)

  const filters = pageContext.filters
  const brandsData = filters.brands.map(brand => {
    return {
      value: brand.prodBrand,
      label: brand.prodBrand
    }
  })
  const colorsData = filters.colors.map(color => {
    return {
      value: color.prodColor,
      label: color.prodColor
    }
  })
  const shopsData = filters.shops.map(shop => {
    return shop.merchant_name
  })

  const [activeBox, setActiveBox] = useState(0)
  const [colors, setColors] = useState(query.color ? query.color : [])
  const [brands, setBrands] = useState(query.brand ? query.brand : [])
  const [delivery, setDelivery] = useState(
    query.delivery ? query.delivery : undefined
  )
  const [shop, setShop] = useState(query.shop ? query.shop : undefined)
  const [sale, setSale] = useState(query.sale ? Boolean(query.sale) : false)

  const onSave = () => {
    setActiveBox(0)

    // We are getting text only from location.search everytime
    const query = queryString.parse(
      typeof window === 'undefined' ? {} : location.search
    )

    const filters = {
      colors: colors,
      brands: brands,
      delivery: delivery,
      shop: shop,
      sale: sale,
      text: query.text || undefined
    }

    applyFilters({ filters, query, location })
  }

  useEffect(() => {
    const onClick = e => {
      let path = []

      if (e.path) {
        path = e.path
      } else {
        let currentElem = e.target
        while (currentElem) {
          path.push(currentElem)
          currentElem = currentElem.parentElement
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
          path.push(document)
        }
        if (path.indexOf(window) === -1) {
          path.push(window)
        }
      }

      const index = path.find(element => {
        return !!(element.dataset && element.dataset.indexNumber)
      })
      if (index) {
        if (activeBox === parseInt(index.dataset.indexNumber)) {
          if (
            (path[0].dataset && path[0].dataset.indexNumber) ||
            (path[1].dataset && path[1].dataset.indexNumber)
          ) {
            setActiveBox(0)
          }
        } else {
          setActiveBox(parseInt(index.dataset.indexNumber))
        }
      } else {
        setActiveBox(0)
      }
      return 1
    }
    window.addEventListener('click', onClick)
    return () => window.removeEventListener('click', onClick)
  }, [activeBox])

  return (
    <Filters>
      <FilterButton
        label='Preis'
        activeBox={activeBox}
        boxNumber={1}
        onSave={onSave}
        price
        large
      >
        {/* <Filters.InputRow> */}
        {/*  <Filters.InputWrapper> */}
        {/*    <Filters.PriceInput */}
        {/*      type='number' */}
        {/*      value={price.min} */}
        {/*      onChange={(value) => { */}
        {/*        if (value && value.target.value <= price.max) { */}
        {/*          setPrice({ min: Number(value.target.value), max: Number(price.max) }) */}
        {/*        } */}
        {/*      }} */}
        {/*    /> */}
        {/*  </Filters.InputWrapper> */}
        {/*  <Filters.InputWrapper> */}
        {/*    <Filters.PriceInput */}
        {/*      type='number' */}
        {/*      value={price.max} */}
        {/*      onChange={(value) => { */}
        {/*        if (value && value.target.value <= priceData.max) { */}
        {/*          setPrice({ min: Number(price.min), max: Number(value.target.value) }) */}
        {/*        } */}
        {/*      }} */}
        {/*    /> */}
        {/*  </Filters.InputWrapper> */}
        {/* </Filters.InputRow> */}
        {/* <InputRange */}
        {/*  formatLabel={value => `${value} €`} */}
        {/*  minValue={priceData.min} */}
        {/*  maxValue={priceData.max} */}
        {/*  value={price} */}
        {/*  onChange={value => { */}
        {/*    // This check is required, because react-input-range is doing it wrong */}
        {/*    if (value && value.min >= priceData.min && value.max <= priceData.max) { */}
        {/*      setPrice({ min: value.min, max: value.max }) */}
        {/*    } */}
        {/*  }} */}
        {/* /> */}
        <Filters.SaleLabel>
          <input
            type='checkbox'
            checked={sale}
            onChange={event => {
              setSale(event.target.checked)
            }}
          />
          Im Sale
        </Filters.SaleLabel>
      </FilterButton>
      <FilterButton
        label='Farbe'
        activeBox={activeBox}
        boxNumber={2}
        onSave={onSave}
      >
        <CheckboxList data={colorsData} values={colors} setData={setColors} />
      </FilterButton>
      <FilterButton
        label='Marke'
        activeBox={activeBox}
        boxNumber={3}
        onSave={onSave}
      >
        <CheckboxList data={brandsData} values={brands} setData={setBrands} />
      </FilterButton>
      <FilterButton
        label='Lieferzeit'
        activeBox={activeBox}
        boxNumber={4}
        onSave={onSave}
      >
        <RadioList data={deliveryData} value={delivery} setData={setDelivery} />
      </FilterButton>
      <FilterButton
        label='Shop'
        activeBox={activeBox}
        boxNumber={5}
        onSave={onSave}
        right
      >
        <RadioList data={shopsData} value={shop} setData={setShop} />
      </FilterButton>
    </Filters>
  )
}

export default FiltersContainer
