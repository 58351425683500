import Image from 'gatsby-image'
import Link from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'
import { Item1, Item2, Item3, Item4, Item5, Item6, Item7, Item8 } from './items'

const VisualGrid = styled('div')`
  margin: 15px 0;
  display: grid;
  grid-template-areas:
    'item1 item1 item2 item3 item4'
    'item1 item1 item2 item3 item5'
    'item6 item6 item7 item8 item8';
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 150px 150px 250px;
  grid-row-gap: 8px;
  grid-column-gap: 8px;

  @media (max-width: 992px) {
    grid-template-areas:
      'item1 item1 item2'
      'item1 item1 item2'
      'item3 item6 item4'
      'item3 item6 item5'
      'item7 item8 item8';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 150px 150px 150px 150px 180px;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

  @media (max-width: 767px) {
    grid-template-areas:
      'item1 item1 item2'
      'item1 item1 item2'
      'item3 item4 item4'
      'item3 item5 item5'
      'item6 item7 item7'
      'item8 item8 item8';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 150px 150px 150px 150px 150px 190px;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

  @media (max-width: 450px) {
    grid-template-areas:
      'item1'
      'item2'
      'item3'
      'item4'
      'item5'
      'item6'
      'item7'
      'item8';
    grid-template-columns: 1fr;
    grid-template-rows: 300px 300px 300px 300px 300px 300px 300px 300px;
    grid-row-gap: 10px;
  }
`

const Label = styled('div')`
  position: absolute;
  width: 30%;
  opacity: 0.9;
  background-color: rgba(242, 241, 241);
  z-index: 1;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-weight: 700;
  line-height: 1.3em;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 6px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 135px;
  min-height: 30px;

  @media (max-width: 767px) {
    font-size: 10px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.3em;
  }
`

const StyledLink = styled(Link)``

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VisualNavigation = props => {
  const visualNaviCollection = [
    {
      label: props.contentful.node.imageNaviLabel1,
      link: props.contentful.node.imageNaviLink1,
      img: props.contentful.node.imgNavi[0].fluid,
      alt: props.contentful.node.imageNaviLabel1
    },
    {
      label: props.contentful.node.imageNaviLabel2,
      link: props.contentful.node.imageNaviLink2,
      img: props.contentful.node.imgNavi[1].fluid,
      alt: props.contentful.node.imageNaviLabel2
    },
    {
      label: props.contentful.node.imageNaviLabel3,
      link: props.contentful.node.imageNaviLink3,
      img: props.contentful.node.imgNavi[2].fluid,
      alt: props.contentful.node.imageNaviLabel3
    },
    {
      label: props.contentful.node.imageNaviLabel4,
      link: props.contentful.node.imageNaviLink4,
      img: props.contentful.node.imgNavi[3].fluid,
      alt: props.contentful.node.imageNaviLabel4
    },
    {
      label: props.contentful.node.imageNaviLabel5,
      link: props.contentful.node.imageNaviLink5,
      img: props.contentful.node.imgNavi[4].fluid,
      alt: props.contentful.node.imageNaviLabel5
    },
    {
      label: props.contentful.node.imageNaviLabel6,
      link: props.contentful.node.imageNaviLink6,
      img: props.contentful.node.imgNavi[5].fluid,
      alt: props.contentful.node.imageNaviLabel6
    },
    {
      label: props.contentful.node.imageNaviLabel7,
      link: props.contentful.node.imageNaviLink7,
      img: props.contentful.node.imgNavi[6].fluid,
      alt: props.contentful.node.imageNaviLabel7
    },
    {
      label: props.contentful.node.imageNaviLabel8,
      link: props.contentful.node.imageNaviLink8,
      img: props.contentful.node.imgNavi[7].fluid,
      alt: props.contentful.node.imageNaviLabel8
    }
  ]
  return (
    <VisualGrid>
      <Item1>
        <StyledLink to={visualNaviCollection[0].link}>
          <StyledImage fluid={visualNaviCollection[0].img} />
          <Label>{visualNaviCollection[0].label}</Label>
        </StyledLink>
      </Item1>
      <Item2>
        <StyledLink to={visualNaviCollection[1].link}>
          <StyledImage fluid={visualNaviCollection[1].img} />
          <Label>{visualNaviCollection[1].label}</Label>
        </StyledLink>
      </Item2>
      <Item3>
        <StyledLink to={visualNaviCollection[2].link}>
          <StyledImage fluid={visualNaviCollection[2].img} />
          <Label>{visualNaviCollection[2].label}</Label>
        </StyledLink>
      </Item3>
      <Item4>
        <StyledLink to={visualNaviCollection[3].link}>
          <StyledImage fluid={visualNaviCollection[3].img} />
          <Label>{visualNaviCollection[3].label}</Label>
        </StyledLink>
      </Item4>
      <Item5>
        <StyledLink to={visualNaviCollection[4].link}>
          <StyledImage fluid={visualNaviCollection[4].img} />
          <Label>{visualNaviCollection[4].label}</Label>
        </StyledLink>
      </Item5>
      <Item6>
        <StyledLink to={visualNaviCollection[5].link}>
          <StyledImage fluid={visualNaviCollection[5].img} />
          <Label>{visualNaviCollection[5].label}</Label>
        </StyledLink>
      </Item6>
      <Item7>
        <StyledLink to={visualNaviCollection[6].link}>
          <StyledImage fluid={visualNaviCollection[6].img} />
          <Label>{visualNaviCollection[6].label}</Label>
        </StyledLink>
      </Item7>
      <Item8>
        <StyledLink to={visualNaviCollection[7].link}>
          <StyledImage fluid={visualNaviCollection[7].img} />
          <Label>{visualNaviCollection[7].label}</Label>
        </StyledLink>
      </Item8>
    </VisualGrid>
  )
}

export default VisualNavigation
