import styled, { css } from 'styled-components'

const StyledLinkCSS = css`
  transition: all 700ms ease;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: black;
  text-transform: capitalize;

  &:hover {
    background-color: #f8f9fa;
    color: #f1574d;
    outline: none;
  }
`

const StyledLink = styled.a`
  ${StyledLinkCSS}
`

export { StyledLinkCSS }
export default StyledLink
