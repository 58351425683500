import React, { useCallback } from 'react'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import ProductItem from '../../components/blocks/ProductItem'
import ProductItemDetails from '../../components/blocks/ProductItemDetails'
import LoadableImage from '../../components/elements/LoadableImage'
import priceFormatter from '../../components/elements/PriceFormatter'

const ItemContainer = styled.div`
  padding: 5px;
`

const ProductItemElement = (item) => {
  const handleDataLayer = useCallback(() => {
    window.dataLayer.push({
      amberCatId: item.node.amberCatId,
      simpleSku: item.node.simpleSku,
      prodColor: item.node.prodColor,
      prodBrand: item.node.prodBrand,
      url_id: item.url_id,
      prodPrice: item.node.prodPrice,
      prodPriceOld: item.node.prodPriceOld,
      prodRanking: item.node.prodRanking,
      prodTitle: item.node.prodTitle,
      merchantName: item.node.merchantName,
      pageType: 'categoryPageTemplate',
      event: 'productClick'
    })
  }, [item])
  return (
    <Grid.Unit
      size={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 }}
      as='li'
    >
      <ItemContainer>
        <ProductItem>
          <ProductItem.StyledLink
            href={item.node.deepLink || item.node.deeplink}
            rel='nofollow noopener'
            target='_blank'
            onClick={handleDataLayer}
          >
            {!!item.node.prodDiscount && (
              <ProductItemDetails.DiscountTag>
                {'-' + Math.floor(100 * item.node.prodDiscount) + '%'}
              </ProductItemDetails.DiscountTag>
            )}
            {item.node.prodImgUrl1Mobile ? (
              <LoadableImage
                srcSet={`${item.node.prodImgUrl1Mobile} 250w, ${item.node.prodImgUrl1} 350w`}
                sizes='(max-width: 767px) 250px, 350px'
                src={item.node.prodImgUrl1}
                key={item.node.prodImgUrl1}
                rel='nofollow noopener'
                alt={item.node.prodTitle}
                height={450}
              />
            ) : (
              <LoadableImage
                src={item.node.prodImgUrl1}
                key={item.node.prodImgUrl1}
                rel='nofollow noopener'
                alt={item.node.prodTitle}
                height={450}
              />
            )}
          </ProductItem.StyledLink>
          <ProductItemDetails>
            <ProductItemDetails.Price>
              <ProductItemDetails.ActualPrice>
                {priceFormatter(item.node.prodPrice)}
              </ProductItemDetails.ActualPrice>
              {!!item.node.prodPriceOld && (
                <ProductItemDetails.Discount>
                  {priceFormatter(item.node.prodPriceOld)}
                </ProductItemDetails.Discount>
              )}
            </ProductItemDetails.Price>
            <ProductItemDetails.H3>
              <ProductItemDetails.StyledLink
                href={item.node.deepLink || item.node.deeplink}
                rel='nofollow noopener'
                target='_blank'
                onClick={handleDataLayer}
              >
                <span>{item.node.prodTitle}</span>
              </ProductItemDetails.StyledLink>
            </ProductItemDetails.H3>
          </ProductItemDetails>
        </ProductItem>
      </ItemContainer>
    </Grid.Unit>
  )
}

export default ProductItemElement
