import styled from 'styled-components'
import Select from './Select'
import Text from './Text'

const SortListSelector = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
  justify-content: flex-end;
`

SortListSelector.Select = Select
SortListSelector.Text = Text

export default SortListSelector
