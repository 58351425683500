import styled from 'styled-components'
import Icon from './Icon'
import Text from './Text'

const FilterButton = styled.div`
  width: 100%;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #000;
  color: #ffffff;
  justify-content: center;
  margin-bottom: 10px;

  @media screen and (min-width: 176px) {
    width: 96%;
  }
`

FilterButton.Icon = Icon
FilterButton.Text = Text

export default FilterButton
