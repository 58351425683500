import styled, { css } from 'styled-components'

const StyledLinkCSS = css`
  display: block;
  width: 100%;
  padding: 0.25rem 0 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  background-color: transparent;
  transition: all 700ms ease;
  cursor: pointer;
  text-decoration: none;
  overflow-wrap: break-word;

  &:hover {
    background-color: #f8f9fa;
    color: #f1574d !important;
    outline: none;
  }

  @media screen and (max-width: 992px) {
    padding: 0.25rem 0;
  }
`

const StyledLink = styled.a`
  ${StyledLinkCSS}
`

export default StyledLink
