import styled from 'styled-components'
import Item from './Item'
import StyledLink from './StyledLink'

const CategoryList = styled.ol`
  font-size: 0.8rem;
  list-style: none;
  padding: 0;
  line-height: 2;
  margin-bottom: 1rem;
  word-wrap: break-word;
`

CategoryList.Item = Item
CategoryList.StyledLink = StyledLink

export default CategoryList
