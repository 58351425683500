import styled from 'styled-components'

const PriceInput = styled('input')`
  padding-left: 8px;
  padding-right: 30px;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  color: #767676;
  font-weight: inherit;
  appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export default PriceInput
