import styled from 'styled-components'

const Content = styled.ol`
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
`

export default Content
