import React from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import useItems from '../../hooks/useItems'
import ItemsDisplay from './ItemsDisplay'
import LoadMore from './LoadMore'

const ProductsContainer = styled(Grid)`
  margin: 0;
`

const ItemsContainer = props => {
  const products = useItems(props.pageContext)

  return (
    <>
      <ProductsContainer
        as='ol'
      >
        <ItemsDisplay
          {...props}
          {...products}
          standaloneItems={props.pageContext.standaloneItems}
          amberUrl={props.pageContext.category.amber_url}
        />
      </ProductsContainer>
      <LoadMore {...products} />
    </>
  )
}

export default ItemsContainer
