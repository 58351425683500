import React, { useCallback, useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'

import APIClient from '../../../api-client'
import Loader from '../../../components/elements/Loader'
import ProductBigItemElementQV from './ProductBigItemElementQV'
import Column from './Column'
import Text from './Text'
import Box from './Box'

const InlineQuickView = (props) => {
  const [item, setItem] = useState(undefined)
  const queryParams = useMemo(() => {
    return queryString.parse(props.location.search)
  }, [props.location.search])

  const handleDataLayer = useCallback(() => {
    window.dataLayer.push({
      amberCatId: item.amberCatId,
      simpleSku: item.simpleSku,
      prodColor: item.prodColor,
      prodBrand: item.prodBrand,
      url_id: item.url_id,
      prodPrice: item.prodPrice,
      prodPriceOld: item.prodPriceOld,
      prodRanking: item.prodRanking,
      prodTitle: item.prodTitle,
      merchantName: item.merchantName,
      pageType: 'categoryInlineQV',
      event: 'productClick'
    })
  }, [item])

  const getProduct = useCallback(
    async (clickId) => {
      const items = await APIClient.getItemBySku(clickId)

      if (items.length > 0) {
        setItem({ ...items[0] })
      }
    },
    []
  )

  useEffect(() => {
    const initialInlineQuickView = async () => {
      await getProduct(queryParams.inlineqv)
    }

    if (queryParams.inlineqv) {
      initialInlineQuickView()
    }
  }, [queryParams, getProduct])

  if (!queryParams.inlineqv) {
    return null
  }

  if (!item && queryParams.inlineqv) {
    return (
      <Column>
        <Text>
          Ihre Suche:
        </Text>
        <Box loading={1}>
          <Loader color='#bfbfbf' />
        </Box>
      </Column>
    )
  }

  const infoArray = [
    {
      label: 'Shop',
      value: item.merchantName
    },
    {
      label: 'Brand',
      value: item.prodBrand
    },
  ]

  if (item.prodDeliveryTime) infoArray.push({
    label: 'Lieferzeit',
    value: item.prodDeliveryTime
  })

  return <ProductBigItemElementQV
          item={item}
          infoArray={infoArray}
          handleDataLayer={handleDataLayer} />
}

export default InlineQuickView
