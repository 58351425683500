import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import LoadMoreButton from '../../components/elements/LoadMoreButton'

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`

const LoadMore = ({ items, status, getMoreItems }) => {
  if (status === 'end') {
    if (items && items.length > 0) {
      return null
    }
    return (
      <div
        style={{
          width: '100%',
          paddingTop: 100,
          paddingBottom: 50,
          textAlign: 'center'
        }}
      >
        Sorry no results for you!
      </div>
    )
  }
  if (status === 'loading') {
    return (
      <LoadMoreContainer>
        <Loader
          type='ThreeDots'
          color='#e7e7e7'
          height={27}
          width={100}
          visible
        />
      </LoadMoreContainer>
    )
  }
  return (
    <LoadMoreContainer>
      <LoadMoreButton onClick={() => getMoreItems(false)}>
        mehr anzeigen
      </LoadMoreButton>
    </LoadMoreContainer>
  )
}

export default LoadMore
