import styled from 'styled-components'

const Save = styled('button')`
  font-size: 16px;
  display: block;
  border: 1px solid #bfbfbf;
  color: #767676;
  background: none;
  height: 50px;
  padding: 16px;
  width: calc(100% - 32px);
  cursor: pointer;
  font-weight: bold;
`

export default Save
