import styled from 'styled-components'

const Header = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(191, 191, 191, 0.6);
  z-index: 1;
  background: #fff;
  display: flex;
  font-size: 18px;
`

export default Header
