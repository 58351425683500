import styled from 'styled-components'

const Text = styled.div`
  color: #000;
  letter-spacing: 0.82px;
  padding: 8px 0;
  display: inline-block;
  word-break: break-word;
  hyphens: auto;
  width: 100%;
  text-decoration: none;
`

export default Text
