import styled from 'styled-components'
import ProductBigItem from '../../../components/blocks/ProductBigItem'


const QVStyledLink = styled(ProductBigItem.StyledLink)`
    
    max-width: 320px;
    margin: auto;

    @media screen and (min-width: 100px) and (max-width: 768px) {
       max-width: 220px; 
    }
`;

export default QVStyledLink