import styled from 'styled-components'

import UniversalButton from '../../../components/elements/UniversalButton/UniversalButton'

const Button = styled(UniversalButton)`
  position: relative;
  bottom: unset;
  left: unset;
  margin-bottom: 15px;
`

export default Button
