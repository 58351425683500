import styled from 'styled-components'

const Title = styled.h3`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  margin-bottom: 20px;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1.2px;
  line-height: 0.8;
  font-weight: 700;
  padding: 0 15px;
`

export default Title
