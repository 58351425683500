import React from 'react'
import styled from 'styled-components'
import Item from './Item'
import StyledLink from './StyledLink'

const Ol = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 12px;
  padding: 0;
  background-color: transparent;

  > li:first-child::before {
    display: none;
  }
`

const Nav = styled.nav`
  font-family: Montserrat, sans-serif;
  color: #222;
`

const BreadcrumbNavigation = props => {
  return (
    <Nav>
      <Ol>{props.children}</Ol>
    </Nav>
  )
}

BreadcrumbNavigation.Item = Item
BreadcrumbNavigation.StyledLink = StyledLink

export default BreadcrumbNavigation
