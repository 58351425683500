import { navigate } from 'gatsby-link'
import queryString from 'query-string'

const applyFilters = ({ filters, query, location }) => {
  query.color = filters.colors
  query.brand = filters.brands
  query.delivery = filters.delivery
  query.shop = filters.shop

  if (filters.sale) {
    query.sale = filters.sale.toString()
  } else {
    query.sale = undefined
  }
  if (filters.delivery) {
    query.delivery = filters.delivery.toString()
  } else {
    query.delivery = undefined
  }
  if (filters.shop) {
    query.shop = filters.shop.toString()
  } else {
    query.shop = undefined
  }
  if (filters.text) {
    query.text = filters.text
  } else {
    query.text = undefined
  }

  navigate(`${location.pathname}?${queryString.stringify(query)}`)
}

export default applyFilters
