import styled, { css } from 'styled-components'

const Box = styled('div')`
  position: relative;
  padding: 12px;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  max-width: 100%;
  overflow: hidden;

  img {
    max-width: 400px;
  }
  
  ${props => props.loading && css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  `};

  ${props => !props.loading && css`
    display: grid;
    grid-template-columns: 33% 66%;
    grid-column-gap: 1%;
    
    > * {
      padding: 0px;
    }
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0;
      
      img {
        max-width: 350px;
      }
      
      > div {
        overflow: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        > * {
          text-align: center;
        }
      }
    }
  `};
`

export default Box
