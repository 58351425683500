import styled from 'styled-components'

const Title = styled.h3`
  font-size: 0.8rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0.5rem;
`

export default Title
