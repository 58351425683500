import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { RadioButton, RadioGroup } from 'react-custom-radio'
import styled from 'styled-components'

const Group = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const RadioIcon = styled(FontAwesomeIcon)`
  display: none;
  position: absolute;
  left: 18px;
`

const ListButton = styled(RadioButton)`
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  font-weight: 400;
  padding: 8px 0 8px 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;

  &.active ${RadioIcon} {
    display: flex;
  }
`

const RadioList = ({ value, data, setData }) => {
  const selectedValue = Array.isArray(value) ? value[0] : value
  return (
    <Group
      selectedValue={selectedValue}
      onChange={e => {
        e === selectedValue ? setData(undefined) : setData(e)
      }}
    >
      {data.map(item => (
        <ListButton key={item} value={item} className='radio-button'>
          <RadioIcon icon={faCheck} />
          {item}
        </ListButton>
      ))}
    </Group>
  )
}

export default RadioList
