import styled from 'styled-components'

const Title = styled.span`
  margin: 16px 0;
  color: #000;
  font-weight: 700;
  height: 1.2em;
  line-height: 1.2em;
  width: 100%;
`

export default Title
