import styled from 'styled-components'

const Description = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #5d5d5d;
  line-height: 1.661;
  letter-spacing: 1.26px;
  text-align: justify;
  margin-bottom: 1rem;
  padding: 0 15px;
`

export default Description
